window._ = require('./instagram-feed.min');

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

const app = new Vue({
    el: '#home_main_app',
    data() {
        return {
        }
    },
    created() {
    },
    mounted: function () {
        $.instagramFeed({
            'username': 'chacraraju',
            'container': "#instagram-feed",
            'display_profile': false,
            'display_biography': false,
            'display_gallery': true,
            'callback': null,
            'styling': true,
            'items': 8,
            'items_per_row': 4,
            'margin': 0,
            'lazy_load': false,
            'on_error': console.error
        });
    },
    methods: {
        clickFeature: function(event) {
            let animations = [
                "animate__animated animate__infinite animate__bounce animate__delay-1s",
                "animate__animated animate__infinite animate__bounce animate__delay-2s",
                "animate__animated animate__infinite animate__bounce animate__delay-3s",
            ];
            
            let parent = $(event.target).parents('.feature');
            let i = parent.index();
            let animation = animations[i];

            if(parent.hasClass(animation)) {
                parent.removeClass(animation);
                let t = setTimeout(() => {
                    parent.toggleClass('active');    
                    clearTimeout(t);
                }, 200);
            } else {
                parent.removeClass('active');
                let t = setTimeout(() => {
                    parent.addClass(animation);
                    clearTimeout(t);
                }, 600);
                
            }
        },
        openContactModal: function() {
            window.bus.$emit('openContactModal');
        }
    }
});
